<template>
  <b-row>
    <b-col sm="12" xl="8">
      <c-card title="Tambah Baru">
        <template slot="action">
          <c-button :to="{name:'partners'}" variant="outline-primary">Kembali</c-button>
          <c-button :loading="isloading" @click="$refs.form.onSubmit()" type="submit">Simpan</c-button>
        </template>
        <c-form ref="form" @submit="confirmSubmit">
          <form-input label="Kode" v-model="formData.code" :rules="{required: true}"></form-input>
          <form-input label="Nama" v-model="formData.name" :rules="{required: true}"></form-input>
          <form-checkbox v-model="formData.is_cash" label="Kas Tunai"></form-checkbox>
          <form-checkbox v-model="formData.is_active" label="Aktif"></form-checkbox>
          <button type="submit" hidden></button>
        </c-form>
      </c-card>
    </b-col>
  </b-row>
</template>

<script>
import CCard from '@/utils/components/CCard.vue'
import FormInput from '@/utils/components/FormInput.vue'
import CForm from '@/utils/components/CForm.vue'
import CButton from '@/utils/components/CButton.vue'
import FormCheckbox from '@/utils/components/FormCheckbox.vue'

export default {
  components: { CCard, FormInput, CForm, CButton, FormCheckbox },
  data(){
    const formData = {
      code:'',
      name:'',
      is_active: true,
      is_cash: true,
    }
    return {
      formData,
      isloading: false
    }
  },
  methods:{
    confirmSubmit(){
      this.confirmation(`submitForm`)
    },
    submitForm(){
      const vm = this
      vm.isloading = true
      const formData = vm.formData
      formData.is_active = formData.is_active ? 1 : 0
      formData.is_cash = formData.is_cash ? 1 : 0
      this.$http.post(`v1/payment_partners/create`,formData).then(() => {
        vm.notify(`Data Berhasil Disimpan!`)
        vm.$router.push({name:'partners'})
        vm.isloading = false
      }).catch((error) => {
        vm.isloading = false
        vm.handleError(error)
      })
    }
  }
}
</script>

<style>

</style>